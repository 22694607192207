const googleMapsKey = 'AIzaSyDrzzovfYyMzTgPUza6KWEvgOFqesFJMtI';

const config = {
  env: 'PROD',
  geolocationAPI: `https://www.googleapis.com/geolocation/v1/geolocate?key=${googleMapsKey}`,
  appMode: process.env.appMode,
  apiUrlV2: 'https://internal-api.fleetops.ai:5055/v1', // Prod V2
  firebaseConfig: {
    apiKey: 'AIzaSyCxSibAMCrrOu9iRSz5bAuPT4PwafE1KmM',
    authDomain: 'fleetops-production.firebaseapp.com',
    databaseURL: 'https://fleetops-production.firebaseio.com',
    projectId: 'fleetops-production',
    storageBucket: 'fleetops-production.appspot.com',
    messagingSenderId: '123222433082',
  },
  googleTagManager: {
    gtmId: 'GTM-K9HSKQB', // FleetOps-Production-Application account
  },
  mixpanelConfig: {
    token: 'e8689fc88684ebf70bd09196b14a458a',
  },
  googleMapsKey,
  staticGoogleMapsURL: 'https://maps.googleapis.com/maps/api/staticmap?',
  featheryFormSdkClientKey: '8e095209-877e-4928-94d9-acb575afc0df',
};

export default config;
